<template>
  <div class="login_form_wrap">
    <Form @submit="onSubmit" class="form_list" validate-trigger="onSubmit">
      <Field v-model="formValue[item.key]" :placeholder="$t(item.label)" v-for="(item, index) in formList"
        :key="`form_item_${index}`" :rules="item.rules || []" :maxlength="item.length || 99" :type="item.type || 'text'"
        :readonly="item.readonly || false" :disabled="item.disabled || false">
        <template #left-icon>
          <i class="iconfont" :class="item.icon" v-if="item.icon"></i>
          <Img height="22" :src="item.img" fit="scale-down" v-if="item.img" />
        </template>
        <template #right-icon v-if="item.isPassword">
          <i class="iconfont" :class="item.type === 'password'
            ? 'icon-yanjing_xianshi_o'
            : 'icon-yanjing_yincang_o'
            " @click="showPassword(item, index)"></i>
        </template>
      </Field>
      <slot name="read">
        <Checkbox v-model="read" shape="square" checked-color="#ebb681">
          {{ $t(readText) }}
        </Checkbox>
      </slot>
      <slot name="confirm">
        <div class="btns">
          <Btn round block native-type="submit" color="#16a1ba" class="confirm">
            {{ $t(confirmText) }}
          </Btn>
        </div>
      </slot>
    </Form>
    <slot name="cancel">
      <div class="btn_wrap">
        <Btn round block plain class="cancel" @click="handleClose">
          {{ $t(cancelText) }}
        </Btn>
      </div>
    </slot>
  </div>
</template>

<script>
import {
  Icon,
  Image as Img,
  Field,
  Form,
  Button as Btn,
  Checkbox,
  Toast,
} from "vant";
export default {
  props: {
    readText: {
      type: String,
    },
    confirmText: {
      type: String,
      default: "Login.Forgot.confirm",
    },
    cancelText: {
      type: String,
    },
    needRead: {
      type: Boolean,
      default: false,
    },
    formList: {
      type: Array,
      default: () => [],
    },
    getRemember: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
    Img,
    Field,
    Form,
    Btn,
    Checkbox,
  },
  data() {
    return {
      formValue: {},
      read: true,
    };
  },
  created() {
    this.initValue();
    const { id: fromid } = this.$route.query;
    if (fromid) {
      this.$set(this.formValue, "fromid", fromid);
    }
    if (this.getRemember) {
      let storePwd = localStorage.getItem("the-pwd-obj");
      if (storePwd) {
        storePwd = JSON.parse(storePwd);
        this.$set(this.formValue, "userphone", storePwd.userphone);
        this.$set(this.formValue, "userpass", storePwd.userpass);
      }
    }
  },
  watch: {
    formList() {
      this.initValue();
    },
  },
  methods: {
    initValue() {
      for (let index = 0; index < this.formList.length; index++) {
        const item = this.formList[index];
        this.$set(this.formValue, item.key, item.defaultValue || "");
      }
    },
    onSubmit(values) {
      if (this.needRead && !this.read) return Toast(this.$t("hint.read"));
      this.$emit("submit", this.formValue, this.read);
    },
    showPassword(item, index) {
      let type = "password";
      if (item.type === "password") {
        type = "text";
      }
      this.formList[index].type = type;
    },
    handleClose() {
      for (const key in this.formValue) {
        if (Object.hasOwnProperty.call(this.formValue, key)) {
          this.formValue[key] = "";
        }
      }
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="less" scoped>
.login_form_wrap {
  .form_list {
    margin: calc(30rem / 16) calc(16rem / 16) 0;
    overflow: hidden;

    .iconfont {
      font-size: calc(20rem / 16);
      margin-right: calc(5rem / 16);
      // color: #00bbfe;
      color: var(--light);
    }

    /deep/ .van-field__control:disabled {
      color: var(--gray2);
      -webkit-text-fill-color: var(--gray2);
    }

    /deep/ .van-cell {
      // border-radius: 10px;
      border-radius: 99px;
      // border: solid 1px #ffffff;
      // background-color: var(--bg2);
      background-color: rgba(0, 0, 0, .6);
      margin-top: calc(19rem / 16);
      line-height: inherit;
      align-items: center;

      &:nth-child(1) {
        margin-top: 0;
      }

      &::after {
        border: none;
      }

      input::placeholder {
        color: var(--gray1);
        // color: #eee;
      }

      input {
        // color: var(--textColor);
        color: var(--light);
      }
    }

    /deep/ .van-checkbox {
      margin-top: calc(20rem / 16);

      .van-icon {
        border-radius: 2px;
      }

      .van-checkbox__label {
        // color: #8ba6ce;
        color: var(--light);
        font-size: calc(13rem / 16);
        letter-spacing: 1px;
      }
    }

    .btns {
      margin-top: calc(42rem / 16);

      .confirm {
        // box-shadow: 6px 6px 29px 2px rgba(4, 115, 222, 0.35);
        border: none;
        background: linear-gradient(90deg, #fad2ae, #e3a466) !important;
      }
    }
  }

  .btn_wrap {
    margin: calc(28rem / 16) calc(16rem / 16);
  }
}
</style>